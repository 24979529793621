import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNavbar]',
})
export class NavbarDirective {
  constructor(private el: ElementRef) {}

  @HostListener('scroll', ['$event']) scrollNav() {

    let element = document.querySelector('.nav-frame') as HTMLElement;

    if (this.el.nativeElement.scrollTop > 80) {


      element.classList.remove('bg-primary-col');
    } else {
      element.classList.add('bg-primary-col');
    }
  }
}
