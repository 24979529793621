<div class="dash-bg">
  <img src="assets/bg.png" alt="background_img" />
</div>
<div class="container mb-5">
  <div class="py-3">
    <h3 class="text-secondary-col">Frequently Asked Questions</h3>
  </div>
  <div class="p-4 bg-translucent">
    <mat-accordion *ngFor="let item of faqsList">
      <mat-expansion-panel
        class="mb-3 border-bottom rounded-0 shadow-none bg-transparent"
      >
        <mat-expansion-panel-header
          [collapsedHeight]="'auto'"
          [expandedHeight]="'auto'"
          class="py-3"
        >
          <mat-panel-title class="text-white">
            <b
              >{{item.title}}</b
            >
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="text-white">
          <p>
            {{item.description}}
          </p>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
