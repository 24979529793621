import { AuthService } from 'src/app/services/auth.service';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { Subscription } from 'rxjs';
// import 'vidstack/player/styles/default/theme.css';
// import 'vidstack/player/styles/default/layouts/video.css';
// import 'vidstack/player';
// import 'vidstack/player/layouts/default';
// import 'vidstack/player/ui';
// import AOS from 'aos';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
  animations: [
    // animation triggers go here
    trigger('fade', [
      // state(),
      transition(':enter,:leave', [style({ opacity: 0 }), animate(4000)]),
    ]), // scroll
    trigger('myInsertRemoveTrigger', [
      //FOR UPDATE IN FUTURE
      // state(),

      transition(':enter,:leave', [
        style({ opacity: 0 }),
        animate(4000, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class LandingComponent implements OnInit {
  isLeftVisible = false;
  isRightVisible = false;

  @ViewChildren('templateRefs') templateRefs!: QueryList<ElementRef>;
  isVisible: boolean[] = [];

  isLoggedIn: boolean = false;
  private isAuth!: Subscription;
  videoSource: string = 'assets/videos/mobileTest.mp4'; // Default desktop video
  videoHeight: string = '100vh'; // Default video height, full screen for mobile

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    // check if there is an authenticated user
    this.isAuth = this.authService
      .loggedInUpdate()
      .subscribe((response: boolean) => {
        this.isLoggedIn = response;
      });
    this.setVideoSource();

    this.isVisible = Array(this.templateRefs.length).fill(false);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.templateRefs.forEach((templateRef, index) => {
      const rect = templateRef.nativeElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Check if the row is within the viewport
      if (rect.top < windowHeight - 100) {
        this.isVisible[index] = true;
      }
    });
  }

  onCanPlay() {
    // Automatically play when video can play
    const videoElement: HTMLMediaElement = document.getElementById(
      'myVideo'
    ) as HTMLMediaElement;
    if (videoElement) {
      videoElement.play();
    }
  }
  onLoadedMetadata() {
    // Ensure video is muted
    const videoElement: HTMLMediaElement = document.getElementById(
      'myVideo'
    ) as HTMLMediaElement;
    if (videoElement) {
      videoElement.muted = true;
    }
  }
  ngOnDestroy() {
    if (this.isAuth) {
      this.isAuth.unsubscribe();
    }
  }
  clll() {
    return window.scrollTo(0, 0);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.setVideoSource(); // Update video source when window is resized
  }

  setVideoSource() {
    console.log(window.innerWidth);
    const isMobile = window.innerWidth <= 768;

    // Check if screen width is 768px or less (mobile device)
    if (isMobile) {
      this.videoSource = 'assets/videos/landingV6.mp4'; // Mobile version
      this.videoHeight = '10vh !important'; // Occupy the full visible height
    } else {
      this.videoSource = 'assets/videos/landingV6.mp4'; // Desktop version
      this.videoHeight = 'auto'; // Allow automatic height adjustment for desktop
    }
  }
}
