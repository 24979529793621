import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DistributionComponent } from './distribution/distribution.component';
import { LandingComponent } from './landing/landing.component';
import { FaqsComponent } from './music-app/faqs/faqs.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { V2LandingComponent } from './landing/v2-landing/v2-landing.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'v2/home', component: V2LandingComponent },
  {path:'distribution',component:DistributionComponent},
  
  {
    path: 'auth',
    loadChildren: () =>
      import('./authenticate/authenticate.module').then(
        (m) => m.AuthenticateModule
      ),
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./music-app/music-app.module').then((m) => m.MusicAppModule),
  },
  { path: 'terms', component: TermsComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'privacy', component: PrivacyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
