import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  private loadingStatus = new Subject<boolean>();

  constructor() { }

  setLoading(state: boolean) {
    this.loadingStatus.next(state)
  }

  getLoadingUpdate() {
    return this.loadingStatus.asObservable();
  }
}
