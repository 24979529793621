import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePipe } from './image.pipe';
import { ProfilePipe } from './profile.pipe';
import { FilePipe } from './file.pipe';
import { AddYearPipe } from './add-year.pipe';
import { PromMonthCalcPipe } from './prom-month-calc/prom-month-calc.pipe';

@NgModule({
  declarations: [ImagePipe, ProfilePipe, FilePipe, AddYearPipe, PromMonthCalcPipe],
  imports: [CommonModule],
  exports: [ImagePipe, ProfilePipe, FilePipe,AddYearPipe,PromMonthCalcPipe],
})
export class PipesModule {}
