import { Component, OnInit } from '@angular/core';
import {Faqs}  from  '../../models/faqs/faqs.model';
@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {

  constructor() { }

  faqsList:Faqs[] = []
  ngOnInit(): void {
    this.faqsList =[
      {
        title:'Where will my videos be distributed?',
        description:'As of right now, all music videos will be distributed to VEVO international platform where it will be featured on Apple TV, Echo, Fire TV, Pluto TV, Roku, Samsung TV Plus, SKY Q, VIZIO, XUMO, Youtube, and many more.'
      },
      {
        title:'How do I collect my royalties?',
        description:'Royalties would be paid either through Paypal or a check by mail.'
      },
      {
        title:'What happens if my videos are not accepted?',
        description:'Submitted videos found to be outside of VEVO’s guidelines, will be declined. Declined videos can be edited for resubmission.'
      },
      {
        title:'How can my supporters find my videos?',
        description:'Your supporters can subscribe to your YouTube OAC (Official Artist Channel) or your YouTube VEVO channel and will receive notification once your videos are published. Your videos would be searchable on all platforms that are affiliated with VEVO, on curated linear channels and VOD (Video On Demand) offerings.'
      },
      {
        title:'How do I Upload a Music Video?',
        description:'Upon signing up to a subscription of your choice, go to the "Dashboard" page then click on the "My Music" menu option on the left of the page. You can then select "Add Music" on the right of the page, which will take you to "New Music". You can then fill in all necessary information and "Save" to upload our music. '
      },
      {
        title:'How much of my royalties will I receive?',
        description:'Artists will receive 80 percent of their royalties.'
      },
      {
        title:'Can I edit music videos once uploaded across platforms?',
        description:'Yes, this process is done by requesting to have the old video removed and adding the new video to be uploaded.'
      },

      {
        title:'Can I upgrade my subscription during the subscription year?',
        description:`Yes, you can upgrade your subscription at any time by going
        to the Subscription Page and selecting your desired new level. You would be
        charged for the full year of your new subscription minus the unused portion of
        your old subscription.  The Dashboard Page will display your new subscription level and the new expiration date which would be a year from today's date.`
      },
    ]
  }

}
