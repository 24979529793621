<div
  *ngIf="!checkRoute()"
  class=" nav-frame "
  style="top: 0; width: 100%; z-index: 1;position: fixed;"
>
  <div class="container py-3">
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand"
      routerLink="/"
        ><img
        (click)="onScrollToTop()" src="assets/logo.png" alt="brand_img" height="55px"
      /></a>
      <button
        class="navbar-toggler bg-transparent text-white"
        type="button"
        (click)="fireEvent()"
        data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
      >
        <i class="fas fa-bars fa-2x"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li *ngIf="isLoggedIn" class="nav-item">
            <a class="navbar-link text-white" routerLink="/app/dashboard"
              >PORTAL</a
            >
          </li>
          <li class="nav-item dropdown">
            <a
              [ngClass]="[
                path === '/distribution' ? 'text-secondary-col' : 'text-white'
              ]"
              class="navbar-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              MENU
            </a>
            <div
              class="dropdown-menu"
              style="background-color: rgba(255, 255, 255, 0.2)"
              aria-labelledby="navbarDropdown"
            >
              <a
                class="dropdown-item text-secondary-col"
                routerLink="/distribution"
                >Digital Distribution</a
              >
            </div>
          </li>
          <!-- <li class="nav-item">
            <a class="navbar-link text-white">HOW IT WORKS</a>
          </li>
          <li class="nav-item">
            <a class="navbar-link text-white">BLOG</a>
          </li> -->
          <li *ngIf="!isLoggedIn" class="nav-item bg-secondary-col">
            <a class="navbar-link-login text-white" routerLink="/auth/login"
              >LOG IN</a
            >
          </li>
          <li *ngIf="!isLoggedIn" class="nav-item">
            <a
              class="navbar-link text-secondary-col"
              routerLink="/auth/register"
              (click)="resetGlobals()"
              >SIGN UP</a
            >
          </li>
          <li *ngIf="isLoggedIn" class="nav-item">
            <a
              class="navbar-link text-secondary-col pointer"
              (click)="logout()"
              >LOG OUT</a
            >
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
<!-- <div *ngIf="!checkRoute()"> -->
<!-- <div class="offcanves-menu">
    <span class="close"><i class="fas fa-times"></i></span>
    <ul>
      <li><a href="">ARTISTS</a></li>
      <li>
        <a href="">service <i class="fal fa-angle-right"></i></a>
        <ul class="off-dropdown">
          <li><a href="">Digital Distribution</a></li>
        </ul>
      </li>
      <li><a href="">HOW IT WORKS</a></li>
      <li><a href="">BLOG</a></li>
      <li><a href="">Log in</a></li>
      <li><a href="">SIGN UP</a></li>
    </ul>
  </div>
  <div class="offcanvas-overlay"></div>
  <header class="header-section">
    <div class="container">
      <div class="header-wrap">
        <div class="row">
          <div class="col-lg-2 d-flex align-items-center">
            <div class="logo">
              <a href="index.html"><img src="assets/logo-home.svg" alt="" /></a>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="main-menu">
              <ul>
                <li><a href="">ARTISTS</a></li>
                <li>
                  <a href="">service <i class="far fa-angle-down"></i></a>
                  <ul class="dropdown">
                    <li><a href="">Digital Distribution</a></li>
                  </ul>
                </li>
                <li><a href="">HOW IT WORKS</a></li>
                <li><a href="">BLOG</a></li>
                <li><a class="active home" href="">Log in</a></li>
                <li><a class="hover home" href="">SIGN UP</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="menu-bar">
          <i class="fal fa-bars"></i>
        </div>
      </div>
    </div>
  </header> -->
<!-- </div> -->
