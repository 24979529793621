import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'profile',
})
export class ProfilePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any) {
    if (value) {
      return this.sanitizer.bypassSecurityTrustUrl(value);
    } else {
      return 'assets/profile.png';
    }
  }
}
