<ng-container
  appNavbar
  class="position-relative"
  style="background-color: #0c0705"
>
  <div *ngIf="loading">
    <app-progress></app-progress>
  </div>
  <app-header
    [toggleState]="toggleState"
    (toggler)="toggleSideNav($event)"
    (scrollToTop)="onScrollToTop()"
  ></app-header>

  <router-outlet
    (activate)="onActivate($event, outlet)"
    #outlet
  ></router-outlet>
  <app-footer></app-footer>
  <ng-container appNavbar> </ng-container>
  <!--
    </mat-drawer-content>
  </mat-drawer-container>
-->
</ng-container>

<ngx-scrolltop
  [backgroundColor]="'green'"
  [symbolColor]="'#fafafa'"
  [size]="30"
  [mode]="'classic'"
  position="right"
  class="scrolltopcustom"
  theme="green"
>
  <img
    (click)="onScrollToTop()"
    src="assets/upicon.png"
    alt="brand_img"
    height="13px"
  />
</ngx-scrolltop>
<ngx-scrolltop></ngx-scrolltop>
