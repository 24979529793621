<div class="position-relative bg-primary-col pt-5 pb-4 px-4 border-rounded-10">
  <div class="position-absolute w-100" style="top: -40px; left: 0">
    <div
      class="rounded-circle bg-secondary-col text-white text-center mx-auto"
      style="height: 80px; width: 80px"
    >
      <i class="fas fa-question fa-3x" style="line-height: 1.6"></i>
    </div>
  </div>
  <div class="text-center">
    <div class="p-4">
      <p class="text-white">
        Are you sure you want to logout from your account?
      </p>
    </div>
    <div>
      <button
        class="btn btn-small rounded-pill btn-outline-light mx-2"
        (click)="closeDialog()"
      >
        No
      </button>
      <button
        class="btn btn-small rounded-pill btn-secondary-col text-white mx-2"
        (click)="logout()"
      >
        Yes
      </button>
    </div>
  </div>
</div>
