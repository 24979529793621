import { Component } from '@angular/core';

@Component({
  selector: 'app-v2-landing',
  templateUrl: './v2-landing.component.html',
  styleUrls: ['./v2-landing.component.css']
})
export class V2LandingComponent {

}
