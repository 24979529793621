import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ProgressService } from './progress.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private progressService: ProgressService,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<unknown>,next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = sessionStorage.getItem('app_login');
    if (!url) {
      const authRequest = request.clone({
        headers: request.headers.set('accept', 'application/json'),
      });
      return next.handle(authRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 500) {
            this.authService.message('Server Error');
          } else {
            this.authService.message(error.error.message);
          }
          this.progressService.setLoading(false);
          const err = error.error.message || error.statusText;
          return throwError(err);
        })
      );
    } else {
      const authToken = this.authService.getToken();
      const authRequest = request.clone({
        headers: request.headers
          .set('accept', 'application/json')
          .set('authorization', 'Token ' + authToken),
      });
      return next.handle(authRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 500) {
            this.authService.message('Server Error');
          } else {
            this.authService.message(error.error.message);
          }
          this.progressService.setLoading(false);
          const err = error.error.message || error.statusText;
          return throwError(err);
        })
      );
    }
  }
}
