import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChartRevenue } from '../models/chartRevenue/chart-revenue';
import { ProgressService } from './progress.service';

import { GlobalConstants } from '../common/global-constants';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseUrl: string = environment.BASE_URL;
  private statusHandle = new Subject<string>();
  private isLoggedIn = new BehaviorSubject<boolean>(false);
  isAuthenticate: boolean = false;
  returnUrl: string = '';
  private subscriptionsData = new Subject();
  subscriptionInfo: any;


  private profileSource = new BehaviorSubject(localStorage.getItem('app_profile') || '{}');
  currentprofile = this.profileSource.asObservable();

  private payoutSource = new BehaviorSubject(false);
  currentPayoutStat = this.payoutSource.asObservable();

  private addMediaData = new BehaviorSubject({});
  currentAddMediaData = this.addMediaData.asObservable();
  constructor(
    private http: HttpClient,
    private router: Router,
    private progressService: ProgressService
  ) { }

  changeCurrentPayoutStat(stat: boolean) {
    this.payoutSource.next(stat)
  }

  message(message: string) {
    this.statusHandle.next(message);
  }

  returnMessage() {
    return this.statusHandle.asObservable();
  }

  signupArtist(data: any) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.baseUrl}auth/registration/`, data).subscribe(
        (response: any) => {
          if (response.plan_id === 0) {
            localStorage.setItem('app_token', response.key);
            this.progressService.setLoading(false);
            resolve(true);
          } else {
            resolve(false);
          }
        },
        (error) => {
          this.progressService.setLoading(false);
          reject(error);
        }
      );
    });
  }

  getPlans() {
    const header: HttpHeaders = new HttpHeaders().set(
      'authorization',
      'Token ' + localStorage.getItem('app_token')
    );
    this.http
      .get(`${this.baseUrl}api/plan/`, { headers: header })
      .subscribe((response) => {
        this.subscriptionInfo = response;
        this.subscriptionsData.next(this.subscriptionInfo);
      });
  }

  getCoupon(data: string) {
    const header: HttpHeaders = new HttpHeaders()
      .set(
        'authorization',
        'Token ' + localStorage.getItem('app_token')
      );
    let usercoupon = 'BLAKFRDAY'
    return this.http
      .get(`${this.baseUrl}api2/coupon/user-coupon/${data}`, { headers: header, })

  }

  subscribePlan(data: any) {
    const header: HttpHeaders = new HttpHeaders().set(
      'authorization',
      'Token ' + localStorage.getItem('app_token')
    );
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.baseUrl}api/user_subscription/`, data, {
          headers: header,
        })
        .subscribe(
          (response: any) => {
            this.progressService.setLoading(false);
            resolve(true);
          },
          (error) => {
            this.progressService.setLoading(false);
            reject(error);
          }
        );
    });
  }

  signupSuccess() {
    sessionStorage.setItem('app_login', 'true');
    this.isAuthenticate = true;
    this.loggedIn(this.isAuthenticate);

    if (this.returnUrl) {
      this.router.navigate([this.returnUrl]);
    } else {
      this.router.navigate(['/app/dashboard']);
    }
  }

  loginArtist(data: any) {
    this.http
      .post(`${this.baseUrl}auth/login/`, data)
      .subscribe((response: any) => {
        if (response.plan_id === 0) {
          this.message('Please complete your registration');
          this.progressService.setLoading(false);

          GlobalConstants.step = 2;
          GlobalConstants.stepper = 'Package';
          GlobalConstants.isLoading = true;

          localStorage.setItem('app_token', response.key);
          this.getPlans();

          this.router.navigate(['/auth/register']);

        } else {

          this.deleteData() //DELETE ANY PREVIOUS DATA IF ANY....
          this.saveData(response.key);
          this.getProfileArtist();
          this.isAuthenticate = true;
          this.loggedIn(this.isAuthenticate);

          if (this.returnUrl) {
            this.router.navigate([this.returnUrl]);
          } else {
            this.router.navigate(['/app/dashboard']);
          }
          this.progressService.setLoading(false);
        }
      });
  }

  checkIfAuthenticatedUser() {

    if (this.getToken() != null &&sessionStorage.getItem('app_login')!=null ) {

      sessionStorage.setItem('app_login', 'true');
      this.isAuthenticate = true;
      this.loggedIn(this.isAuthenticate);

    }else{
      // sessionStorage.setItem('app_login', 'true');
      this.isAuthenticate = false;
      this.loggedIn(this.isAuthenticate);
    }
  }

  autoAuth() {
    this.isAuthenticate = true;
    this.loggedIn(this.isAuthenticate);
  }

  forgotArtist(data: any) {
    this.http
      .post(`${this.baseUrl}api/password_reset/`, data)
      .subscribe((response: any) => {

        if (response.status == 'OK') {

          this.message('Reset link has been sent Successfuly');
          this.router.navigate(['/auth/login']);
          this.progressService.setLoading(false);
        } else {
          this.message('An error occured');
          this.progressService.setLoading(false);
        }

      });
  }

  resetArtist(data: any) {
    this.http
      .post(
        `${this.baseUrl}api/password_reset/confirm/`,
        data
      )
      .subscribe((response: any) => {
        if (response.status == 'OK') {

          this.message('Password Reset Successful');
          this.router.navigate(['/auth/login']);
          this.progressService.setLoading(false);
        } else {
          this.message('An error occured');
          this.progressService.setLoading(false);
        }

      });
  }

  getProfileArtist() {
    this.http.get(`${this.baseUrl}api/user_profile/`).subscribe((response) => {
      console.log(response);

      this.saveProfileData(response)
    });
  }

  getFav_video_Count() {
    const header: HttpHeaders = new HttpHeaders().set(
      'authorization',
      'Token ' + localStorage.getItem('app_token')
    );
    return this.http.get(`${this.baseUrl}api/fav_video_count`, { headers: header });

  }
  getRevenue_count_charts(start_date: string, end_date: string) {
    const header: HttpHeaders = new HttpHeaders().set(
      'authorization',
      'Token ' + localStorage.getItem('app_token')
    );

    // 2021-11-05T22:29:39.807Z
    const opts = new HttpParams({ fromString: `start_date=${start_date}&end_date=${end_date}` });

    return this.http.get<ChartRevenue>(`${this.baseUrl}api/revenue_count_charts`, { headers: header, params: opts });

  }

  getCurrent_sub_info() {
    const header: HttpHeaders = new HttpHeaders().set(
      'authorization',
      'Token ' + localStorage.getItem('app_token')
    );
    return this.http.get(`${this.baseUrl}api/current_sub_info`, { headers: header });

  }

  checkUserArtistLimit() {
    const header: HttpHeaders = new HttpHeaders().set(
      'authorization',
      'Token ' + localStorage.getItem('app_token')
    );
    return this.http.get(`${this.baseUrl}api/check_user_artist_limit`, { headers: header });


  }
  checkUserValidSubscription() {
    const header: HttpHeaders = new HttpHeaders().set(
      'authorization',
      'Token ' + localStorage.getItem('app_token')
    );
    return this.http.get(`${this.baseUrl}api/check_user_valid_sub`, { headers: header });


  }
  total_music_uploads() {
    const header: HttpHeaders = new HttpHeaders().set(
      'authorization',
      'Token ' + localStorage.getItem('app_token')
    );
    return this.http.get(`${this.baseUrl}api/total_music_uploads`, { headers: header });

  }

  updateProfileArtist(data: any, userId: number) {
    console.log(data);

    this.http

      .put(`${this.baseUrl}api/user_profile/${userId}/`, data)
      .subscribe((response) => {

        this.progressService.setLoading(false);
        this.getProfileArtist();
      });
  }

  changePassword(data: any) {
    this.http
      .post(`${this.baseUrl}auth/password/change/`, data)
      .subscribe((response) => {
        this.progressService.setLoading(false);
        this.router.navigate(['/app/my-account']);
      });
  }

  logoutArtist() {
    this.deleteData();
    this.isAuthenticate = false;
    this.loggedIn(this.isAuthenticate);
    this.router.navigate(['']);
    this.progressService.setLoading(false);
  }

  saveData(token: string) {
    localStorage.setItem('app_token', token);
    sessionStorage.setItem('app_login', 'true');
  }



  getProfileData(){
    // let profileData = localStorage.getItem('app_profile');
    let profileData = JSON.parse(localStorage.getItem('app_profile') || '{}');

    // let jsonData = JSON.parse(profileData)
    if (profileData){

      return profileData
    }
  }

  saveProfileData(data: any) {

    localStorage.setItem('app_profile', JSON.stringify(data));

    this.profileSource.next(localStorage.getItem('app_profile') || '{}')
    // sessionStorage.setItem('app_login', 'true');
  }


  deleteData() {
    localStorage.removeItem('app_token');
    sessionStorage.removeItem('app_login');
    localStorage.removeItem('app_profile');

  }

  loggedIn(val: boolean) {
    this.isLoggedIn.next(val);
  }

  loggedInUpdate() {
    return this.isLoggedIn.asObservable();
  }

  getAuth() {
    return this.isAuthenticate;
  }

  getToken() {
    return localStorage.getItem('app_token');
  }

  getPlansUpdate() {
    return this.subscriptionsData.asObservable();
  }







  changeAddData(data: any) {
    this.addMediaData.next(data)
  }
}
