<div *ngIf="!checkRoute()">
  <footer>
    <div class="container">
      <div class="py-5">
        <div class="row">
          <div class="col-lg-4">
            <div class="py-3 text-white border-bottom-4 border-secondary-col">
              <h4 class="mb-0">Social Media</h4>
            </div>
            <div class="mt-3 text-white">
              <small>
              </small>
              <div class="line-listing mt-3 text-secondary-col">
                <ul>
                  <li class="soc-med">
                    <a href="https://www.instagram.com/ryddym.music/" style="color: green;" target="_blank"><i
                        class="fab fa-instagram fa-lg"></i></a>
                  </li>

                  <li class="soc-med">
                    <a href="https://m.facebook.com/ryddymmusicgroup/" style="color: green;" target="_blank"><i
                        class="fab fa-facebook-f fa-lg"></i></a>
                  </li>
                  <li class="soc-med">
                    <a href="https://twitter.com/Ryddym1" style="color: green;" target="_blank">
                      <svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        width="25" height="25" viewBox="0 0 1080 1080" xml:space="preserve">

                        <defs>
                        </defs>
                        <g transform="matrix(1 0 0 1 540 540)" id="c924e304-981c-4f02-b20a-b60d32f809ad">
                          <rect
                            style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1; visibility: hidden;"
                            vector-effect="non-scaling-stroke" x="-540" y="-540" rx="0" ry="0" width="1080"
                            height="1080" />
                        </g>
                        <g transform="matrix(1 0 0 1 540 540)" id="3065a6b0-e80c-4c08-8a99-c7770e68e4e9">
                        </g>
                        <g transform="matrix(15 0 0 15 540 540)">
                          <path
                            style="stroke: rgb(0,128,1); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,128,1); fill-rule: nonzero; opacity: 1;"
                            transform=" translate(-36, -36)"
                            d="M 42.5 31.2 L 66 6 L 60 6 L 39.8 27.6 L 24 6 L 4 6 L 28.6 39.6 L 4 66 L 10 66 L 31.3 43.2 L 48 66 L 68 66 L 42.5 31.2 z M 12.9 10 L 20.9 10 L 59 62 L 51 62 L 12.9 10 z"
                            stroke-linecap="round" />
                        </g>
                        <g transform="matrix(NaN NaN NaN NaN 0 0)">
                          <g style="">
                          </g>
                        </g>
                        <g transform="matrix(NaN NaN NaN NaN 0 0)">
                          <g style="">
                          </g>
                        </g>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="py-3 text-white border-bottom-4 border-secondary-col">
              <h4 class="mb-0">Explore</h4>
            </div>
            <div class="my-3 text-white">

              <p class="pointer " routerLink="/terms">
                <span class="pointer exp-re"> Terms & Conditions</span>
              </p>
              <p class="pointer" routerLink="/privacy">
                <span class="pointer exp-re">Privacy Policy</span>
              </p>
              <p class="pointer">
                <span class="pointer exp-re">About Us</span>
              </p>
              <p class="pointer" routerLink="/faqs">
                <span class="pointer exp-re">FAQ</span>
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="py-3 text-white border-bottom-4 border-secondary-col">
              <h4 class="mb-0">Contact</h4>
            </div>
            <div class="my-3 text-white">
              <div class="d-flex">
                <span class="text-secondary-col ">
                  <i class="fa fa-envelope" aria-hidden="true"></i>

                </span>
                <p class="ml-2">
                  <span class="pointer exp-re">
                    <a class="text-white" href="mailto:ryddymmusic@gmail.com"> ryddymmusic@gmail.com</a>
                  </span>
                </p>
              </div>
              <div class="d-flex">
                <span class="text-secondary-col"><i class="fas fa-phone-alt"></i></span>
                <p class="ml-2">
                  <span class="pointer exp-re">
                    <a class="text-white" href="tel:(347) 395- 2294"> (347) 395- 2294</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5 text-center text-white">
        <p>&copy; 2020 Learning. All Rights Reserved</p>
      </div>
    </div>
  </footer>
</div>
