<div class="position-relative">
  <video
    autoplay
    oncanplay="this.play()"
    onloadedmetadata="this.muted = true"
    loop
    id="myVideo"
    playsinline
    preload="auto"
    [ngStyle]="{ height: videoHeight, width: '100%' }"
    style="pointer-events: none; object-fit: cover"
  >
    <source [src]="videoSource" type="video/mp4" />
    <!-- Fallback content in case video fails to load -->
    <p>
      Your browser does not support the video element. Please click the play
      button below.
    </p>
    <!-- You can also add an image with a play button here -->
    <!-- <iframe src="assets/videos/landingV6.mp4" id="myVideo"  style="pointer-events: none;object-fit: cover; height: auto; width: 100% !"frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> -->
  </video>

  <!-- https://player.vimeo.com/video/213717578?loop=1&autoplay=1&background=1&title=0&byline=0&portrait=0&player_id=iframe46430 -->
  <!--<div class="position-absolute h-100 w-100" style="top: 0">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div
        class="container text-white text-center roboto-font"
        style="max-width: 768px"
        @fade
      >
        <h1 class="roboto-font landing-heading mb-3">Music for Everyone</h1>
        <p >
          Let us help you grow your fan base and manage your videos on top music
          platforms, empowering artists and labels to succeed and earn more
          revenue globally!
        </p>
      </div>
    </div>
  </div> -->
</div>

<div class="position-relative" style="position: relative">
  <div class="container h-100" style="padding: 30px; margin: 30px auto">
    <div
      class="row rowadjust fade-in"
      [class.visible]="isVisible[0]"
      #templateRefs
    >
      <!-- Left Container -->
      <div class="col-lg-6 mar">
        <div>
          <span class="text-white firtP roboto-font">
            Entrust us to help you reach new heights and expand your fan base!
            Distribute and manage your videos on the world's leading music video
            platform!
          </span>
        </div>
      </div>

      <!-- Right Container -->
      <div class="col-lg-6">
        <div class="d-flex flex-wrap">
          <div class="firsLa">
            <img
              class="w-100"
              src="assets/landingpage/landingpage1.jpeg"
              alt="landing one"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row fade-in" [class.visible]="isVisible[1]" #templateRefs>
      <!-- Left Container -->
      <div class="col-lg-6 mar">
        <div class="d-flex flex-wrap">
          <div class="firsLa">
            <img
              class="w-100"
              src="assets/landingpage/landingpage2.jpeg"
              alt="landing two"
            />
          </div>
        </div>
      </div>

      <!-- Right Container -->
      <div class="col-lg-6">
        <div>
          <span class="text-white firtP roboto-font">
            Keep the power in your hands as we passionately help artists and
            labels succeed in spreading their expressive vision through the
            music industry's visual language.
          </span>
        </div>
      </div>
    </div>

    <div
      class="row rowadjust fade-in"
      [class.visible]="isVisible[2]"
      #templateRefs
    >
      <!-- Left Container -->
      <div class="col-lg-6 mar">
        <div>
          <span class="text-white firtP">
            Give your vision a chance to reach its full potential by giving your
            music videos an extra push on an international platform while you
            earn more revenue!
          </span>
        </div>
      </div>

      <!-- Right Container -->
      <div class="col-lg-6">
        <div class="d-flex flex-wrap">
          <div class="firsLa">
            <img
              class="w-100"
              src="assets/landingpage/landingpage3.png"
              alt="landing three"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col d-flex justify-content-center">
  <button
    class="distribute-btn"
    style="background-color: green"
    role="button"
    *ngIf="!isLoggedIn"
    routerLink="/auth/register"
  >
    GET STARTED
  </button>
</div>

<!--  -->
<hr style="border: none; height: 0.2vh; color: #333; background-color: #333" />
