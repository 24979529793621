import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppRoutingModule } from './app-routing.module';
import { LoadingModule } from './loading/loading.module';
import { AppComponent } from './app.component';
import { ProgressComponent } from './progress/progress.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LandingComponent } from './landing/landing.component';
import { DistributionComponent } from './distribution/distribution.component';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './services/auth.interceptor';
import { NavbarDirective } from './directives/navbar.directive';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PackagesComponent } from './shared/packages/packages.component';
import { PipesModule } from '../app/pipes/pipes.module';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { ArtistNoSubscriptionComponent } from './music-app/dialogs/artist-no-subscription/artist-no-subscription.component';
import { V2LandingComponent } from './landing/v2-landing/v2-landing.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LandingComponent,
    ProgressComponent,
    DistributionComponent,
    NavbarDirective,
    TermsComponent,
    PrivacyComponent,
    ScrollToTopComponent,
    ArtistNoSubscriptionComponent,
    V2LandingComponent,
    // PackagesComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatDialogModule,
    LoadingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    PipesModule,
    NgxScrollTopModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
