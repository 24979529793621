import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<LogoutComponent>,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  logout() {
    this.authService.logoutArtist();
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
