import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addYear'
})
export class AddYearPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {

    var d = new Date(value);
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var hour = d.getHours()
    var minute = d.getMinutes()
    var sec = d.getSeconds()

    var c = new Date(year+1,month,day,hour,minute,sec).toDateString(); //CAN ADD EXTRA DAYS HERE 
 
    return c;
  }

 
}
