import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-artist-no-subscription',
  templateUrl: './artist-no-subscription.component.html',
  styleUrls: ['./artist-no-subscription.component.css']
})
export class ArtistNoSubscriptionComponent implements OnInit {

  constructor(
    private router: Router,) { }

  ngOnInit(): void {
  }

  upgradeSubscription(){
    this.router.navigate(['/app/subscriptions']);
  }

}
