import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'promMonthCalc',
})
export class PromMonthCalcPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    var noOfMonths;
    var noOfHours;
    if (value >= 30) {
      // Round the number to the nearest month
      noOfMonths = Math.round(value / 30);
      return '*' + noOfMonths.toString() + ' Months';
    } else if (value < 1) {
      noOfHours = 24 / value;
      return noOfHours.toString() + ' Hours';
    }
    return null;
  }
}
