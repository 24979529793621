import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
})
export class TermsComponent implements OnInit {
  marginTop: boolean = false;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: boolean) {}

  ngOnInit(): void {
    if (this.data) {
      this.marginTop = this.data;
    }
  }
}
