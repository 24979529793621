import {
  Component,
  Inject,
  ElementRef,
  OnDestroy,
  OnInit,
  HostListener,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { LogoutComponent } from './authenticate/logout/logout.component';
import { GlobalConstants } from './common/global-constants';
import { AuthService } from './services/auth.service';
import { ProgressService } from './services/progress.service';

import * as AOS from 'aos';

import { DOCUMENT, ViewportScroller } from '@angular/common';
import { fromEvent, Observable } from 'rxjs';
// import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { map } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Ryddym Music';
  private statusHandle: Subscription = new Subscription();
  private loadingStatus: Subscription = new Subscription();
  loading: boolean = false;
  toggleState: boolean = false;
  toggleMenu: boolean = false;
  private isAuth!: Subscription;
  isLoggedIn: boolean = false;
  private mainContentDiv!: ElementRef<HTMLElement>;

  readonly showScroll$: Observable<boolean> = fromEvent(
    this.document,
    'scroll'
  ).pipe(map(() => this.viewport.getScrollPosition()?.[1] > 0));
  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private progressService: ProgressService,
    private authService: AuthService,
    private el: ElementRef,

    @Inject(DOCUMENT) private readonly document: Document,
    private readonly viewport: ViewportScroller
  ) {}

  ngOnInit(): void {
    AOS.init();
    this.authService.checkIfAuthenticatedUser();
    this.isAuth = this.authService
      .loggedInUpdate()
      .subscribe((response: boolean) => {
        this.isLoggedIn = response;
      });
    this.statusHandle = this.authService
      .returnMessage()
      .subscribe((response) => {
        const message = response;
        this.openSnackBar(message);
      });
    this.loadingStatus = this.progressService
      .getLoadingUpdate()
      .subscribe((response: boolean) => {
        this.loading = response;
      });
    const auth = sessionStorage.getItem('app_login');
    if (auth === 'true') {
      this.authService.autoAuth();
    } else {
      return;
    }
  }

  onActivate(e: any, outlet: any) {
    outlet.scrollTop = 0;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e: any) {
    // Your Code Here
  }
  onScrollToTop(): void {
    this.viewport.scrollToPosition([0, 0]);
  }
  resetGlobals() {
    GlobalConstants.isLoading = false;
    GlobalConstants.step = 1;
    GlobalConstants.stepper = 'Create Account';
  }
  toggleSideNav(event: boolean) {
    this.toggleState = event;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 20000,
    });
  }

  logout() {
    const dialog = this.dialog.open(LogoutComponent, {
      maxWidth: '550px',
      maxHeight: '450px',
      backdropClass: 'dialogBack',
    });

    dialog.afterClosed().subscribe(() => {});
  }

  ngOnDestroy() {
    if (this.statusHandle) {
      this.statusHandle.unsubscribe();
    }
    if (this.loadingStatus) {
      this.loadingStatus.unsubscribe();
    }
    if (this.isAuth) {
      this.isAuth.unsubscribe();
    }
  }
}
