<div class="p-5">
  <div class="text-center mb-4">
    <i
      class="fa fa-check fa-3x bg-secondary-col rounded-circle p-4 text-white"
    ></i>
  </div>
  <div class="text-center">
    <h3 class="">YOU DONT HAVE AN ACTIVE SUBSCRIPTION. PLEASE CONSIDER GETTING ONE </h3>

    <a
    class="btn btn-secondary-col btn-medium rounded-pill"
    (click)="upgradeSubscription()"
    >UPGRADE</a
  >
  </div>
</div>
