<!-- <div>
  <img
    style="object-fit: cover; height: 80vh; width: 100%"
    src="assets/distribution.jpg"
    alt="distribution_img"
  />
</div> -->
<div class="bg-black py-5 bod">
  <div class="container"  style="margin-top:30px;box-shadow: 10px;">
    <div class="text-center mb-5">
      <div class="mx-auto" style="max-width: 768px">
        <h1 class="stardosstencil-font landing-heading mb-5" style="color:green">Distribute With Us</h1>
        <h2 class="stardosstencil-font" style="color:#9ec9ff;margin-bottom: 45px;">Reach millions of viewers using VEVO’s International platform  </h2>
        <div class="container img-mids mb-5">
          <img
            class="img-fluid"

            src="assets/distribution-page/musicdis1.png"
            alt="landing one"
          />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="text-center mb-5">
        <div class="mx-auto" style="max-width: 768px">
          <p class="firtP">
            Starting from the ground up, we are able to form what seems like a
            personal relationship with you and your unique needs. We use cutting
            edge technology to precisely deliver your videos worldwide in the
            most effective manner, allowing you to focus on your mission.
          </p>
        </div>
      </div>
    </div>

    <div class="container img-mids mb-5">
      <img
        class="img-fluid"
        src="assets/distribution-page/musicdis2.png"
        alt="landing one"
      />
    </div>
    <div class="container">
      <div class="text-center mb-5">
        <div class="mx-auto" style="max-width: 768px">
          <p class="firtP">
            Everything is in house, meaning we do everything from screening,and
            upload to release. We mean business! No third party, no middle man,
            no servers equals no mixups. Making sure all your ducks are in a
            row, your hard work won't be done in vain.
            </p>

            <p class="firtP">We at Ryddym aim to
              ensure you receive the quickest turnaround possible, putting your
              mind at ease as your artistry is delivered just the way you want it
              and earns all that it deserves.
            </p>
        </div>
      </div>
    </div>

    <div class="container img-mids mb-5">
      <img
        class="w-100 img-fluid music3"
        src="assets/distribution-page/musicdis3.png"
        alt="landing one"
      />
    </div>

    <div class="container">
      <div class="text-center mb-5">
        <div class="mx-auto" style="max-width: 768px">
          <p class="firtP-2">Where you can see your videos:</p>
          <p class="firtP-2">Apple TV, Comcast, Echo Fire Tv, Foxxum, Hisense Vidaa, CH LG Channels, Netrange, Now TV, Pluto TV, Redbox, Roku, Samsung, Samsung TV Plus, SkyQ, TELSTRA TV, T. Mobile, Vewd, Virgin Media, Vizio, Xite, Xumo, YouTube</p>
        </div>
      </div>
    </div>
  </div>
</div>
