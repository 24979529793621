<div class="container py-5 mt-5 bod">
  <div class="pb-3">
    <h3 class="text-secondary-col">Privacy Policy</h3>
  </div>
  <div class="p-4 bg-translucent text-white">
    <small> Last updated: Nov 6 2020 </small>
    <hr class="border-dark" />
    <h3 class="text-secondary-col mainH">Policies for Ryddym Music</h3>

    <h5></h5>
    <p></p>
    <p>
      Protecting your private information is our priority. This Statement of Privacy applies to <a href="https://www.ryddym.com/">www.ryddym.com</a>, and Ryddym LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Ryddym include <a href="https://www.ryddym.com/">www.ryddym.com</a> and Ryddym LLC. The Ryddym website is a Digital Music Distribution site. By using the Ryddym website, you consent to the data practices described in this statement.
    </p>

    <h4>Collection of your Personal Information</h4>
    <span>
      <p>In order to better provide you with products and services offered, Ryddym may collect personally identifiable information, such as your: </p>

      <ol style="list-style-type: lower-roman;">
        <li>First and Last Name </li>
        <li>E-mail Address </li>
        <li>Phone Number </li>
      </ol>


       <p>If you purchase Ryddym's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.

       We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services.

       </p>
       <span>These may include:</span>
       <ol style="list-style-type: lower-roman;">
        <li>Registering for an account; </li>
        <li>Entering a sweepstakes or contest sponsored by us or one of our partners;</li>
        <li>Signing up for special offers from selected third parties; </li>
        <li>Sending us an email message;</li>
        <li>Submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</li>

      </ol>
    </span>


    <h4>Use of your Personal Information </h4>
    <p>Ryddym collects and uses your personal information to operate and deliver the services you have requested.

      Ryddym may also use your personally identifiable information to inform you of other products or services available from Ryddym and its affiliates.
      </p>

    <h4>Sharing Information with Third Parties </h4>
    <p>Ryddym does not sell, rent or lease its customer lists to third parties.

      Ryddym may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Ryddym, and they are required to maintain the confidentiality of your information.

      </p>
          <span>
            Ryddym may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to:
      </span>
      <ol style="list-style-type: lower-roman;">
        <li> conform to the edicts of the law or comply with legal process served on Ryddym or the site</li>
        <li>protect and defend the rights or property of Ryddym; and/or</li>
        <li>act under exigent circumstances to protect the personal safety of users of Ryddym, or the public.</li>
        </ol>
    <h4>Automatically Collected Information</h4>
    <p>Information about your computer hardware and software may be automatically collected by Ryddym. </p>

      <span>
        This information can include:
      </span>

      <ol style="list-style-type: lower-roman;">
        <li>   your IP address</li>
        <li>browser type</li>
        <li>domain names</li>
        <li>access times</li>
        <li>referring website addresses.</li>
      </ol>


      <p>
        This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Ryddym website.
    </p>

    <h4>Links</h4>
    <p>This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.
    </p>

    <h4>Right to Deletion </h4>
    <span>
      <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will: </p>

      <ol style="list-style-type: lower-roman;">
        <li>Delete your personal information from our records; and</li>
        <li>Direct any service providers to delete your personal information from their records. </li>
      </ol>
      <p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:</p>

      <ol style="list-style-type: lower-roman;">
        <li>Complete the transaction for which the personal information was clilected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us; </li>
        <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;</li>
        <li>Debug to identify and repair errors that impair existing intended functionality;</li>
        <li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law; </li>
        <li>Comply with the California Electronic Communications Privacy Act;</li>
        <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent; </li>
        <li>Enable sliely internal uses that are reasonably aligned with your expectations based on your relationship with us; </li>
        <li>Comply with an existing legal obligation; or </li>
        <li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information. </li>
      </ol>
    </span>


    <h4>Children Under Thirteen
    </h4>
    <p>Ryddym does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website. </p>

    <h4>E-mail Communications</h4>
    <p>From time to time, Ryddym may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from Ryddym or click on a link therein.

      If you would like to stop receiving marketing or promotional communications via email from Ryddym, you may opt out of such communications by clicking on the UNSUBSCRIBE button.
      </p>

    <h4>Changes to this Statement </h4>
    <p>Ryddym reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information.
    </p>
    <span>  Your continued use of the website and/or Services available after such modifications will constitute your:</span>
    <ol style="list-style-type: lower-roman;">
    <li>Acknowledgment of the modified Privacy Policy</li>
    <li>Agreement to abide and be bound by that Policy.</li>
    </ol>

    <h4>Contact Information </h4>
    <p>
      Ryddym welcomes your questions or comments regarding this Statement of Privacy. If you believe that Ryddym has not adhered to this Statement, please contact Ryddym at: <br><br>

      Ryddym LLC <br>


      Effective as of September 01, 2021
      </p>

    <!-- <h5></h5>
    <p></p>
    -->
  </div>
</div>
