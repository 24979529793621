<div class="d-flex justify-content-center py-3">
  <div class="d-flex align-items-center mx-1" style="height: 30px; width: 30px">
    <div
      class="bg-secondary-col rounded-circle mx-auto dot1"
      style="height: 30px; width: 30px"
    ></div>
  </div>
  <div class="d-flex align-items-center mx-1" style="height: 30px; width: 30px">
    <div
      class="bg-secondary-col rounded-circle mx-auto dot2"
      style="height: 30px; width: 30px"
    ></div>
  </div>
  <div class="d-flex align-items-center mx-1" style="height: 30px; width: 30px">
    <div
      class="bg-secondary-col rounded-circle mx-auto dot3"
      style="height: 30px; width: 30px"
    ></div>
  </div>
</div>
