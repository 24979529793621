<div
  class="position-fixed"
  style="
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1001;
  "
>
  <div class="window">
    <div class="spinner"></div>
  </div>
</div>
