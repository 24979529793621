<div class="container py-5 mt-5 bod">
  <div class="bg-primary-col border-rounded-10 scrolly">
    <div class="container py-5">
      <div class="pb-3">
        <h3 class="text-secondary-col">Terms & Conditions</h3>
      </div>
      <div class="p-4 bg-translucent text-white">
        <small> Last updated: Nov 6 2020 </small>
        <hr class="border-dark" />
        <h3 class="text-secondary-col">Terms & Conditions for Ryddym Music</h3>
        <h5>Agreement between User and https://www.ryddym.com/</h5>
        <p>
          Welcome to https://www.ryddym.com/. The https://www.ryddym.com/ website (the "Site") is comprised of various
          web pages operated by Ryddym LLC ("Ryddym"). https://www.ryddym.com/ is offered to you conditioned on your
          acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use
          of https://www.ryddym.com/ constitutes your agreement to all such Terms. Please read these terms carefully,
          and keep a copy of them for your reference. https://www.ryddym.com/ is a Digital Music Distribution Site.
          Ryddym provides digital music distribution services for Artists, Artist Managers, and Record Labels that would
          distribute their content to premium music platforms.
        </p>

        <h5>Privacy</h5>
        <p>
          Your use of https://www.ryddym.com/ is subject to Ryddym's Privacy Policy. Please review our Privacy Policy,
          which also governs the Site and informs users of our data collection practices.
        </p>

        <h5>Electronic Communications</h5>

        <p>
          Visiting https://www.ryddym.com/ or sending emails to Ryddym constitutes electronic communications. You
          consent to receive electronic communications and you agree that all agreements, notices, disclosures and other
          communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement
          that such communications be in writing.
        </p>

        <h5>Your Account</h5>


        <p>
          If you use this site, you are responsible for maintaining the confidentiality of your account and password and
          for restricting access to your computer, and you agree to accept responsibility for all activities that occur
          under your account or password. You may not assign or otherwise transfer your account to any other person or
          entity. You acknowledge that Ryddym is not responsible for third party access to your account that results
          from theft or misappropriation of your account. Ryddym and its associates reserve the right to refuse or
          cancel service, terminate accounts, or remove or edit content in our sole discretion.
        </p>

        <h5>Children Under Thirteen</h5>

        <p>
          Ryddym does not knowingly collect, either online or offline, personal information from persons under the age
          of thirteen. If you are under 18, you may use https://www.ryddym.com/ only with permission of a parent or
          guardian.
        </p>

        <h5>Cancellation/Refund Policy</h5>
        You may cancel your subscription at any time. Any cancellations made after 60 days of service will not qualify
        for a full refund. Please contact us at ryddymmusic@gmail.com with any questions.
        <p></p>

        <h5>Links to Third Party Sites/Third Party Services</h5>

        <p>
          https://www.ryddym.com/ may contain links to other websites ("Linked Sites''). The Linked Sites are not under
          the control of Ryddym and Ryddym is not responsible for the contents of any Linked Site, including without
          limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Ryddym is
          providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement
          by Ryddym of the site or any association with its operators.

          Certain services made available via https://www.ryddym.com/ are delivered by third party sites and
          organizations. By using any product, service or functionality originating from the https://www.ryddym.com/
          domain, you hereby acknowledge and consent that Ryddym may share such information and data with any third
          party with whom Ryddym has a contractual relationship to provide the requested product, service or
          functionality on behalf of https://www.ryddym.com/ users and customers.

        </p>

        <h5>No Unlawful or Prohibited Use/Intellectual Property</h5>

        <p>
          You are granted a non-exclusive, non-transferable, revocable license to access and use https://www.ryddym.com/
          strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to Ryddym
          that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use
          the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other
          party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information
          through any means not intentionally made available or provided for through the Site.

          All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation
          thereof, and any software used on the Site, is the property of Ryddym or its suppliers and protected by
          copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and
          abide by all copyright and other proprietary notices, legends or other restrictions contained in any such
          content and will not make any changes thereto.

          You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
          derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Ryddym
          content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any
          protected content, and in particular you will not delete or alter any proprietary rights or attribution
          notices in any content. You will use protected content solely for your personal use, and will make no other
          use of the content without the express written permission of Ryddym and the copyright owner. You agree that
          you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or
          implied, to the intellectual property of Ryddym or our licensors except as expressly authorized by these
          Terms.

        </p>

        <h5>International Users</h5>

        <p>
          The Service is controlled, operated and administered by Ryddym from our offices within the USA. If you access
          the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree
          that you will not use the Ryddym Content accessed through https://www.ryddym.com/ in any country or in any
          manner prohibited by any applicable laws, restrictions or regulations.
        </p>

        <h5>Indemnification</h5>

        <p>
          You agree to indemnify, defend and hold harmless Ryddym, its officers, directors, employees, agents and third
          parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or
          arising out of your use of or inability to use the Site or services, any user postings made by you, your
          violation of any terms of this Agreement or your violation of any rights of a third party, or your violation
          of any applicable laws, rules or regulations. Ryddym reserves the right, at its own cost, to assume the
          exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you
          will fully cooperate with Ryddym in asserting any available defenses.
        </p>

        <h5>Arbitration</h5>

        <p>In the event the parties are not able to resolve any dispute between them arising out of or concerning these
          Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity
          for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration
          pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the
          American Arbitration Association, or a similar arbitration service selected by the parties, in a location
          mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon
          it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or
          arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to
          recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in
          regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions,
          whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The
          parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision.
          The entire dispute, including the scope and enforceability of this arbitration provision shall be determined
          by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.
        </p>

        <h5>Class Action Waiver</h5>

        <p>
          Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations
          and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS
          AGAINST THE OTHER ONLY IN EACH INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE
          CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION
          AGAINST THE OTHER. Further, unless both you and Ryddym agree otherwise, the arbitrator may not consolidate
          more than one person's claims, and may not otherwise preside over any form of a representative or class
          proceeding.
        </p>

        <h5>Liability Disclaimer</h5>

        <p>
          THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE
          INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. RYDDYM LLC
          AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.

          RYDDYM LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY,
          TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON
          THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE,
          PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. RYDDYM
          LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION,
          SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.

          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL RYDDYM LLC AND/OR ITS SUPPLIERS BE LIABLE
          FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER
          INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY
          CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED
          SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS,
          SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE,
          WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF RYDDYM LLC OR ANY OF ITS
          SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE
          EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT
          APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR
          SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.


        </p>


        <h5>ISRC Assignment</h5>

        <p>
          Ryddym has been appointed as an official ISRC (International Standard Recording Code) Manager by the Recording
          Industry Association of America (RIAA) and would be able to assign ISRCs to your audio and video recording
          with
          your permission. By selecting the “Please assign an ISRC” checkbox when uploading a recording, you assert the
          following: (i) You are the rights owner of the recording and your recording was never assigned an ISRC before,
          and
          that you do give Ryddym permission to verify via SoundExchange that your recording was never assigned an ISRC.
          If
          an ISRC is found for your recording, Ryddym will notify you via email that an ISRC was found for your
          recording
          and the ISRC will be used for publishing your recording. (ii) You give Ryddym your unwavering permission, as
          the
          rights owner of the recording, to assign a new ISRC for your recording and use the ISRC for publishing and
          reporting royalties for your recording. Ryddym will promptly notify you via email of the new ISRC assignment
          for
          your recording. Ryddym will also then report the assignment of the new ISRC for your recording to
          SoundExchange,
          which will include key metadata associated with your recording.
        </p>

        <h5>Revenue and Payment</h5>

        <p>Ryddym shall pay Users Eight Percent (80%) of revenue received from ads served on their content. Revenue is
          income actually received by, or credited to, Ryddym, that is derived from Digital Distribution and Publishing
          of Users’ Content. Income received by Ryddym may be subject to taxes, surcharges, or fees imposed by
          government agencies, or Ryddym's partners, before payment is sent to Ryddym. Ryddym shall have no obligation
          to pay Users Revenue that Ryddym has not actually received, until Ryddym receives such Revenue. Revenue shall
          be paid monthly once Users’ total earnings exceeds $100 US. Each payment, by paper check or by electronic
          transfer\payment, will be accompanied by a detailed electronic statement showing Revenue generating viewership
          of the Content. If Users have not received payment, or a statement indicating that no payment is due, then
          Users may promptly notify Ryddym. In no event shall Ryddym be deemed in breach of its payment obligations
          under this Agreement if Users have not received payment, or a statement. However, Ryddym may be deemed in
          breach of its payment obligations if Ryddym fails to make payments, or provide a statement, thirty days after
          receiving such notice from Users. No payment shall be made to Users when less than $100 US is payable to the
          User. If payment is not made to a User for this reason, the balance shall accrue to the User's account, and be
          paid in the first month in which the User's account reflects a balance greater than $100 US.</p>

        <h5>Taxes</h5>
        <p>
          The amount paid by Ryddym to the Users includes all applicable sales taxes, municipal, state or otherwise.
          Users shall be responsible for and shall pay all applicable sales tax, municipal, state or otherwise. THE USER
          AGREES TO ACCEPT FULL RESPONSIBILITY FOR AND AGREES TO DEFEND AND INDEMNIFY RYDDYM FROM THE PAYMENT OF ANY AND
          ALL TAXES THAT MAY BE LAWFULLY DUE FROM THE CLIENT TO ANY GOVERNMENTAL ENTITY INCLUDING BUT NOT LIMITED TO
          INCOME TAXES, FICA OR OTHER WITHHOLDING TAXES, FRANCHISE TAX, SALES TAX, ETC.
        </p>
        <h5>Termination/Access Restriction</h5>

        <p>
          Ryddym reserves the right, in its sole discretion, to terminate your access to the Site and the related
          services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this
          agreement is governed by the laws of the State of New York and you hereby consent to the exclusive
          jurisdiction and venue of courts in New York in all disputes arising out of or relating to the use of the
          Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these
          Terms, including, without limitation, this section.

          You agree that no joint venture, partnership, employment, or agency relationship exists between you and Ryddym
          as a result of this agreement or use of the Site. Ryddym's performance of this agreement is subject to
          existing laws and legal process, and nothing contained in this agreement is in derogation of Ryddym's right to
          comply with governmental, court and law enforcement requests or requirements relating to your use of the Site
          or information provided to or gathered by Ryddym with respect to such use. If any part of this agreement is
          determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the
          warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision
          will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the
          original provision and the remainder of the agreement shall continue in effect.

          Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and Ryddym
          with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether
          electronic, oral or written, between the user and Ryddym with respect to the Site. A printed version of this
          agreement and of any notice given in electronic form shall be admissible in judicial or administrative
          proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as
          other business documents and records originally generated and maintained in printed form. It is the express
          wish to the parties that this agreement and all related documents be written in English.

        </p>

        <h5>Changes to Terms</h5>

        <p>
          Ryddym reserves the right, in its sole discretion, to change the Terms under which https://www.ryddym.com/ is
          offered. The most current version of the Terms will supersede all previous versions. Ryddym encourages you to
          periodically review the Terms to stay informed of our updates.
        </p>
        <h5>Contact Us</h5>


        <br />
        <p>
          Ryddym welcomes your questions or comments regarding the Terms:
          <br /><br />

          Ryddym LLC <br />

          Email Address: <br>
          <a href="mailto:ryddymmusic@gmail.com">ryddymmusic@gmail.com</a> <br>

          Effective as of September 01, 2021
        </p>

      </div>
    </div>
  </div>

</div>
